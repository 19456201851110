import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "components/style";
import * as SC from "./style";

const Offer = () => {
  const images = useStaticQuery(graphql`
    query indexImages {
      headerImage: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2729, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headerImageMobile: file(relativePath: { eq: "background_mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 575, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headerSubtitleImage: file(relativePath: { eq: "flags.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const headerImage = images.headerImage.childImageSharp.fluid;
  const headerImageMobile = images.headerImageMobile.childImageSharp.fluid;
  const headerSubtitleImage = images.headerSubtitleImage.childImageSharp.fluid;

  return (
    <SC.Shadow>
      <SC.Section id="offer">
        <SC.MainImage alt="granich-school-main-image" fluid={headerImage} />
        <SC.MainImageMobile fluid={headerImageMobile} />
        <Container>
          <SC.Wrapper>
            <SC.Title>Школа Гранича</SC.Title>
            <SC.Descr>
              Осознанно развиваем{" "}
              <SC.FlagsImg alt="flags" fluid={headerSubtitleImage} /> <br />{" "}
              твёрдые навыки дизайнеров
            </SC.Descr>
          </SC.Wrapper>
        </Container>
      </SC.Section>
    </SC.Shadow>
  );
};

export default Offer;
