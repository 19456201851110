import React from "react";
import CheckIcon from "assets/svgs/check-inter-medium.svg";
import FoundationOfGraphicDesignIcon from "assets/svgs/foundation-of-graphic-design/foundation-logo-short-2-5.svg";
import FigmaIcon from "assets/svgs/courses-order/figma.svg";
import IllustratorIcon from "assets/svgs/courses-order/illustrator.svg";
import MidJourneyIcon from "assets/svgs/courses-order/midjourney.svg";
import MetaphorsIcon from "assets/svgs/courses-order/metaphors.svg";
import PhotoshopIcon from "assets/svgs/courses-order/photoshop.svg";
import CopyrightIcon from "assets/svgs/courses-order/copyrighting.svg";
import DesignByHandIcon from "assets/svgs/courses-order/design-by-hand.svg";
import COURSE_STATUSES from "constants/course-statuses";
import COURSES from "constants/courses-names";
import { getCourseStatus } from "utilities/course-utils";
import * as SC from "./style";

const useCoursesOrder = (courses = []) => {
  const getCourseData = courseTitle => {
    const data = courses.find(
      course => course?.data?.coursetitle?.text === courseTitle
    );
    const tags = data?.data?.body
      ?.find(el => el?.slice_type === "tags")
      ?.items.map(({ coursetag: { text } }) => text);
    const authors = data?.data?.body?.find(
      el => el?.slice_type === "course_creators"
    )?.items;
    if (!data) return {};
    return { uid: data?.uid, ...data.data, tags, authors };
  };

  const granichMidjourneyCourseData = getCourseData("Granich Midjourney");
  const foundationCourseData = getCourseData("Фундамент Графдизайна");
  const metaphorsCourseData = getCourseData("Айдентика через Метафоры");
  const graphicSystemByHandCourseData = getCourseData("Графдизайн руками");
  const graphicFigmaData = getCourseData("Granich Figma");

  const offlineBlock = {
    blockTitle: "Путь оффлайна",
    courses: [
      {
        courseTitle: "Графдизайн руками",
        courseIcon: (
          <SC.CourseIconWrapper>
            <DesignByHandIcon />
          </SC.CourseIconWrapper>
        ),
        courseType: "Мастер-класс",
        courseTags: [],
        courseAdditionalTags: [
          <SC.CourseStatusTag courseStatus={COURSE_STATUSES.open}>
            <CheckIcon />В продаже
          </SC.CourseStatusTag>,
        ],
        courseLink: `/${graphicSystemByHandCourseData.uid}`,
      },
    ],
  };
  const desktopOffline = {
    ...offlineBlock,
    customStyle: "disabled-mobile",
    id: "offline-desktop",
  };
  const mobileOffline = {
    ...offlineBlock,
    customStyle: "disabled-desktop",
    id: "offline-mobile",
  };

  const dataCoursesOrder = [
    [
      {
        blockTitle: "Изучение инструментов",
        type: "dashed",
        id: "start",
        desktopArrowRight: <SC.StyledDesktopArrowRight />,
        mobileArrowBottom: <SC.StyledMobileArrowBottom />,
        courses: [
          {
            courseTitle: "Granich Figma",
            courseIcon: (
              <SC.CourseIconWrapper>
                <FigmaIcon />
              </SC.CourseIconWrapper>
            ),
            courseType: "Мастер-класс",
            courseStatus: COURSE_STATUSES.onSale,
            courseTags: [],
            courseLink: `/${graphicFigmaData.uid}`,
          },
          {
            courseTitle: "Granich Midjourney",
            courseIcon: (
              <SC.CourseIconWrapper>
                <MidJourneyIcon />
              </SC.CourseIconWrapper>
            ),
            courseType: "Мастер-класс",
            courseStatus: COURSE_STATUSES.onSale,
            courseLink: `/${granichMidjourneyCourseData.uid}`,
            courseTags: [],
          },
        ],
      },
    ],
    [
      {
        blockTitle: "Путь композиций",
        id: "composition",
        desktopArrowRight: <SC.StyledDesktopArrowRight />,
        desktopArrowBottom: <SC.StyledDesktopArrowBottom />,
        mobileArrowBottom: <SC.StyledMobileArrowBottom />,
        mobileProlongatedArrowBottom: (
          <SC.StyledProlongatedMobileArrowBottomOne />
        ),
        courses: [
          {
            courseTitle: foundationCourseData.coursetitle?.text,
            courseIcon: (
              <SC.CourseIconWrapper>
                <FoundationOfGraphicDesignIcon />
              </SC.CourseIconWrapper>
            ),
            courseType: foundationCourseData.coursetype,
            courseStatus: getCourseStatus(foundationCourseData),
            courseTags: foundationCourseData.tags,
            courseStartAndEnd: foundationCourseData.coursestartandend?.text,
            courseStream: foundationCourseData.coursestream,
            courseAdditionalBlock: (
              <SC.FoundationAdditionalBlock>
                <PhotoshopIcon />
                <IllustratorIcon /> — Тоже изучим
              </SC.FoundationAdditionalBlock>
            ),
            courseLink: `/${foundationCourseData.uid}`,
          },
        ],
      },
      {
        blockTitle: "Путь маркетинга",
        id: "marketing",
        courses: [
          {
            courseTitle: "Графдизайн копирайтинга",
            courseIcon: (
              <SC.CourseIconWrapper>
                <CopyrightIcon />
              </SC.CourseIconWrapper>
            ),
            courseType: "Мастер-класс",
            courseStatus: COURSE_STATUSES.onSale,
            courseTags: ["Маркетинг"],
            courseLink: "/graphic-design-copywriting",
          },
        ],
      },
    ],
    [
      {
        blockTitle: "Путь насмотренности",
        id: "visual experience",
        desktopArrowRight: <SC.StyledDesktopArrowRight />,
        desktopArrowBottom: <SC.StyledDesktopArrowBottom />,
        mobileArrowBottom: <SC.StyledMobileArrowBottom />,
        mobileProlongatedArrowBottom: <SC.StyledProlongatedMobileArrowBottom />,
        courses: [
          {
            courseTitle: (
              <>
                Графсистемы
                <SC.CourseStatusTag
                  style={{ marginTop: "0.8vw" }}
                  courseStatus={COURSE_STATUSES.open}
                >
                  <CheckIcon />В продаже
                </SC.CourseStatusTag>
                <SC.StyledCourseTag>Мастер-класс</SC.StyledCourseTag>
              </>
            ),
            courseTags: [],
            courseAdditionalBlock: (
              <SC.MasterClassesIconsList>
                {[
                  {
                    data: getCourseData(COURSES.graphicSystemSwiss),
                  },
                  {
                    data: getCourseData(COURSES.graphicSystemAiryLayout),
                  },
                  {
                    data: getCourseData(COURSES.graphicSystemNeoGeo),
                  },
                  {
                    data: getCourseData(COURSES.graphicSystemUI),
                  },
                  {
                    data: getCourseData(COURSES.graphicSystemTechno),
                  },
                  {
                    data: getCourseData(COURSES.graphicSystemConstructivism),
                  },
                  {
                    data: getCourseData(COURSES.graphicSystemCollage),
                  },
                ].map(
                  ({ data: { uid, coursetitle, coursedescription } }, idx) => (
                    <SC.StyledTooltip
                      html={
                        <SC.StyledTooltipContext>
                          <div>
                            <b>{coursetitle?.text}</b>
                          </div>
                          <div>{coursedescription?.text}</div>
                        </SC.StyledTooltipContext>
                      }
                      animation="shift"
                      arrow
                      interactive
                      theme="light"
                      key={idx}
                    >
                      <SC.CourseLink href={`/${uid}`} target="_blank">
                        <SC.CourseIconWrapper>
                          <img src={`/images/logos/${uid}.svg`} alt={uid} />
                        </SC.CourseIconWrapper>
                      </SC.CourseLink>
                    </SC.StyledTooltip>
                  )
                )}
              </SC.MasterClassesIconsList>
            ),
          },
        ],
      },
      desktopOffline,
    ],
    [
      {
        blockTitle: "Путь символизма",
        id: "symbolism",
        courses: [
          {
            courseTitle: metaphorsCourseData.coursetitle?.text,
            courseIcon: (
              <SC.CourseIconWrapper>
                <MetaphorsIcon />
              </SC.CourseIconWrapper>
            ),
            courseType: metaphorsCourseData.coursetype,
            courseStatus: getCourseStatus(metaphorsCourseData),
            courseTags: metaphorsCourseData.tags,
            courseStartAndEnd: metaphorsCourseData.coursestartandend?.text,
            courseStream: metaphorsCourseData.coursestream,
            courseLink: "https://artpavlushin.ru/campus/graphic-metaphors",
          },
        ],
      },
      mobileOffline,
    ],
  ];

  return { dataCoursesOrder };
};

export default useCoursesOrder;
