import styled from "styled-components";
import UnderlineLinkIcon from "assets/svgs/offer-underline-link.svg";
import Img from "gatsby-image";

export const Shadow = styled.div`
  filter: drop-shadow(0 1vw 1vw rgba(0, 0, 0, 0.2));
  position: relative;
  z-index: 1;
`;

export const Section = styled.div`
  width: 100%;
  height: 43.5vw;
  clip-path: circle(300vw at 50% -257vw);
  background: var(--granich-black);
  @media only screen and (max-width: 575px) {
    height: 134vw;
    position: relative;
    clip-path: circle(300vw at 50% -167vw);
    overflow: hidden;

    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(10, 10, 10, 0.6) 10%,
        transparent 100%
      );
      z-index: 1;
    }
  }
`;

export const Title = styled.h1`
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 8.2vw;
  color: white;
  line-height: 0.88;
  letter-spacing: -0.4vw;
  width: 37vw;
  margin-bottom: 2.2vw;
  margin-top: 1vw;

  @media only screen and (max-width: 575px) {
    width: 100%;
    font-size: 18vw;
    letter-spacing: -0.8vw;
    width: 100px;
    margin-bottom: 9.5vw;
    margin-top: 17vw;
    width: 100%;
    line-height: 0.9;
    position: relative;
    z-index: 2;
    // margin-left: -0.5vw;
  }
`;
export const Descr = styled.div`
  margin-left: 0.5vw;
  color: white;
  letter-spacing: 0.05vw;
  font-size: 2.5vw;
  line-height: 1.2;

  font-family: EB Garamond;
  font-style: italic;
  position: relative;
  z-index: 2;
  font-weight: 500;
  svg {
    transition: opacity 0.2s ease;
    opacity: 1;
  }
  span {
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    letter-spacing: inherit;
    position: relative;
    :hover {
      cursor: pointer;
      svg {
        opacity: 0;
      }
    }
    :focus {
      outline: none;
    }
    :active {
      outline: none;
    }
  }
  @media only screen and (max-width: 575px) {
    font-size: 7.5vw;
    margin-left: 1vw;
  }
`;

export const UnderlineIcon = styled(UnderlineLinkIcon)`
  width: 20.8vw;
  position: absolute;
  bottom: -1.1vw;
  left: 0.2vw;
  @media only screen and (max-width: 575px) {
    width: 65vw;
    bottom: -3.2vw;
  }
`;

export const FlagsImg = styled(Img)`
  margin-left: 0.1vw;
  top: 0.2vw;
  width: 2vw;
  display: inline-block;
  @media only screen and (max-width: 575px) {
    width: 6vw;
    margin-left: 1.5vw;
  }
`;

export const MainImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100% !important;
  user-select: none;
  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const MainImageMobile = styled(Img)`
  position: absolute !important;
  top: -1.5vw;
  left: -3vw;
  z-index: 0;
  width: 100% !important;
  display: none;
  height: 100% !important;
  user-select: none;
  filter: contrast(0.9);
  transform: scale(1.07);

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 65vw;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 10%,
      transparent 100%
    );
    z-index: 100;
  }

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 55vw;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 50%, transparent 100%);
    z-index: 100;
  }

  @media only screen and (max-width: 575px) {
    display: block;
  }
`;

export const Wrapper = styled.div`
  padding: 12.2vw 0 5vw 0;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  @media only screen and (max-width: 575px) {
    padding: 28vw 0vw;
  }
`;
