const COURSES = {
  graphicSystemAiryLayout: "Графсистема Воздух",
  graphicSystemSwiss: "Графсистема Швейцария",
  graphicSystemUI: "Графсистема UI‑дизайн",
  graphicSystemNeoGeo: "Графсистема Нео‑Гео",
  graphicSystemTechno: "Графсистема Техно",
  graphicSystemConstructivism: "Графсистема Конструктивизм",
  graphicSystemCollage: "Графсистема Коллаж",
  graphicSystemExpressism: "Графсистема Экспрессизм",
  graphicSystemNeoslav: "Графсистема Неослав",
  graphicSystemBrutalism: "Графсистема Брутализм",
};

export default COURSES;
