import { Container } from "components/style";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { getCourseStatusIcon } from "utilities/course-utils";
import useCoursesOrder from "./useCoursesOrder";
import * as SC from "./style";

const CourseCard = ({
  courseData: {
    courseTitle,
    courseIcon,
    courseTags = [],
    courseType,
    courseStatus,
    courseAdditionalBlock,
    courseAdditionalTags = [],
    courseStartAndEnd,
    courseStream,
  },
}) => (
  <SC.CourseCard>
    <SC.CourseHeader>
      {courseIcon}
      <SC.CourseTitle>{courseTitle}</SC.CourseTitle>
    </SC.CourseHeader>
    <SC.CourseTags>
      {courseStatus && (
        <SC.CourseStatusTag courseStatus={courseStatus}>
          {getCourseStatusIcon(courseStatus)}
          {courseStatus}
        </SC.CourseStatusTag>
      )}
      {courseAdditionalTags.map((additionalTag, idx) => (
        <React.Fragment key={idx}>{additionalTag}</React.Fragment>
      ))}
      {courseType && (
        <>
          {courseType === "Мастер-класс" ? (
            <SC.CourseTag>{courseType}</SC.CourseTag>
          ) : (
            <SC.CourseSecondaryTag courseType={courseType}>
              {courseType}
            </SC.CourseSecondaryTag>
          )}
        </>
      )}
      {courseStream && (
        <SC.CourseSecondaryTag>{courseStream} поток</SC.CourseSecondaryTag>
      )}
      {courseStartAndEnd && (
        <SC.CourseSecondaryTag>{courseStartAndEnd}</SC.CourseSecondaryTag>
      )}
      {courseTags.map((tag, idx) => (
        <SC.CourseTag key={idx}>{tag}</SC.CourseTag>
      ))}
    </SC.CourseTags>
    {courseAdditionalBlock}
  </SC.CourseCard>
);

const CoursesOrder = ({ courses }) => {
  const { dataCoursesOrder } = useCoursesOrder(courses);
  return (
    <SC.Section id="courses">
      <SC.SectionWrapper>
        <SC.StyledGraphArc />
        <Container style={{ position: "relative", zIndex: "0" }}>
          <SC.SectionTitle>
            Учебная программа <span>Школы</span>
          </SC.SectionTitle>
          <SC.SectionInfo>
            <SC.SectionInfoCourseType>
              <SC.CourseSecondaryTag>Курс</SC.CourseSecondaryTag>— обучение
              в группе, с дедлайнами
            </SC.SectionInfoCourseType>
            <SC.SectionInfoCourseType>
              <SC.CourseTag>Мастер-класс</SC.CourseTag>— обучение индивидуально,
              без дедлайнов
            </SC.SectionInfoCourseType>
          </SC.SectionInfo>
          <SC.ColumnsWrapper>
            {dataCoursesOrder.map(column => (
              <SC.Column key={uuidv4()}>
                {column.map(block => (
                  <SC.Block
                    type={block.type}
                    id={block.id}
                    key={uuidv4()}
                    customStyle={block.customStyle}
                  >
                    {block.desktopArrowRight}
                    {block.desktopArrowBottom}
                    {block.mobileArrowBottom}
                    {block.mobileProlongatedArrowBottom}
                    <SC.BlockTitle>{block.blockTitle}</SC.BlockTitle>
                    {block.courses.map(courseData => {
                      if (courseData.courseLink) {
                        return (
                          <SC.CourseLink
                            href={courseData.courseLink}
                            target="_blank"
                            key={uuidv4()}
                          >
                            <CourseCard courseData={courseData} />
                          </SC.CourseLink>
                        );
                      }
                      return (
                        <CourseCard key={uuidv4()} courseData={courseData} />
                      );
                    })}
                  </SC.Block>
                ))}
              </SC.Column>
            ))}
          </SC.ColumnsWrapper>
        </Container>
      </SC.SectionWrapper>
    </SC.Section>
  );
};

export default CoursesOrder;
